<template>
    <section class="pt-8 pt-md-5 pb-8 pb-md-14">
        <div class="container">
            <Spinner v-if="!loaded" />
            <div class="row" v-else>
                <div class="col-12">
                    <h1 class="mb-0 fw-bold">Datasets</h1>
                    <p
                        class="mb-6 text-muted"
                        v-if="Object.keys(errors).length < 1"
                    >
                        Create datasets
                    </p>
                    <p class="mb-6 text-danger" v-for="error in errors.__all__">
                        {{ error }}
                    </p>

                    <!-- Hashtags -->

                    <form class="mb-6" @submit.prevent="create">
                        <div class="form-group">
                            <label class="form-label" for="hashtags"
                                >Hashtags</label
                            >
                            <div class="dropdown">
                                <input
                                    type="last_name"
                                    class="form-control dropdown-toggle"
                                    :class="{ 'is-invalid': errors.hashtags }"
                                    id="hashtags"
                                    placeholder="#fashion #sports"
                                    v-model="form.hashtags"
                                    @change="check"
                                    data-bs-toggle="dropdown"
                                />
                                <div class="dropdown-menu dropdown-menu w-100">
                                    <button
                                        v-for="suggestion in suggestions.hashtags"
                                        type="button"
                                        class="btn btn-light d-block w-100 text-start mb-2"
                                        @click="chooseMultiSuggetion"
                                    >
                                        {{ suggestion }}
                                    </button>
                                </div>
                            </div>
                            <small
                                class="form-text text-danger"
                                v-for="error in errors.hashtags"
                                >{{ error }}</small
                            >
                        </div>

                        <!-- usernames -->

                        <div class="form-group">
                            <label class="form-label" for="usernames"
                                >Usernames</label
                            >
                            <div class="dropdown mb-4">
                                <input
                                    type="last_name"
                                    class="form-control dropdown-toggle"
                                    :class="{ 'is-invalid': errors.username }"
                                    id="usernames"
                                    placeholder="Enter usernames like 'username, username' or download .csv"
                                    v-model="form.usernames"
                                    @change="check"
                                    data-bs-toggle="dropdown"
                                    :disabled="fileUpload"
                                />
                                <div
                                    class="dropdown-menu dropdown-menu w-100 gap-3"
                                >
                                    <button
                                        v-for="suggestion in suggestions.usernames"
                                        type="button"
                                        class="btn btn-light d-block w-100 text-start mb-2"
                                        @click="chooseMultiSuggetion"
                                    >
                                        {{ suggestion }}
                                    </button>
                                </div>
                            </div>
                            <small
                                class="form-text text-danger"
                                v-for="error in errors.usernames"
                                >{{ error }}</small
                            >

                            <input
                                id="file"
                                type="file"
                                style="display: none"
                                accept=""
                                @change="uploadFile"
                            />

                            <button
                                class="btn btn-sm btn-primary lift"
                                type="button"
                                :disabled="form.usernames !== ''"
                            >
                                <label for="file">Click to upload files</label>
                            </button>
                            <small v-show="fileUploadError" class="text-danger"
                                >Wrong file format</small
                            >
                        </div>

                        <!-- Location -->

                        <!-- <InputMod :suggestions="suggestions.locations" /> -->
                        <div class="form-group">
                            <label class="form-label" for="location"
                                >Location</label
                            >
                            <div class="dropdown">
                                <input
                                    type="last_name"
                                    class="form-control dropdown-toggle"
                                    :class="{ 'is-invalid': errors.location }"
                                    id="location"
                                    placeholder=""
                                    v-model="form.location"
                                    @change="check"
                                    data-bs-toggle="dropdown"
                                />
                                <div
                                    class="dropdown-menu dropdown-menu w-100 gap-3"
                                >
                                    <button
                                        v-for="suggestion in suggestions.locations"
                                        type="button"
                                        class="btn btn-light d-block w-100 text-start mb-2"
                                        @click="chooseSingleSuggetion"
                                    >
                                        {{ suggestion }}
                                    </button>
                                </div>
                            </div>
                            <small
                                class="form-text text-danger"
                                v-for="error in errors.location"
                                >{{ error }}</small
                            >
                        </div>

                        <!-- Count -->

                        <div class="form-group">
                            <label class="form-label" for="count">Count</label>
                            <input
                                name="count"
                                type="number"
                                min="50"
                                class="form-control"
                                :class="{ 'is-invalid': errors.count }"
                                id="count"
                                placeholder="100000"
                                v-model.number="form.count"
                                @input="check"
                            />
                            <small
                                class="form-text text-danger"
                                v-for="error in errors.count"
                                >{{ error }}</small
                            >
                        </div>
                        <div class="form-group">
                            <label :class="{ 'is-invalid': errors.full }">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    v-model="form.full"
                                    @change="check"
                                />
                                Full user info
                            </label>
                        </div>
                        <!-- <div class="form-group">
                            <label class="form-label" for="actuality"
                                >Actuality</label
                            >
                            <select
                                class="form-select form-select-xs"
                                :class="{ 'is-invalid': errors.actuality }"
                                id="actuality"
                                name="actuality"
                                v-model="form.actuality"
                                @change="check"
                            >
                                <option value="d">day</option>
                                <option value="w">week</option>
                                <option value="m">month</option>
                            </select>
                            <small
                                class="form-text text-danger"
                                v-for="error in errors.actuality"
                                >{{ error }}</small
                            >
                        </div> -->
                        <div class="form-group">
                            <label class="form-label">Amount:</label>
                            <p class="mb-6">
                                ${{ amount }}, ${{ price }} per row (Your
                                balance: ${{ balance }})
                            </p>
                        </div>
                        <button
                            class="btn w-100 btn-primary"
                            type="submit"
                            :disabled="disabled"
                        >
                            Create
                        </button>
                    </form>

                    <div class="table-responsive">
                        <vuetable
                            ref="vuetable"
                            :api-mode="false"
                            :data="tableData"
                            class="ui celled table unstackable table-scroll"
                            wrapper-class="vuetable-wrapper ui basic segment"
                            table-wrapper=".vuetable-wrapper"
                            :fields="fields"
                            @vuetable:pagination-data="onPaginationData"
                        >
                            <template slot="actions" slot-scope="props">
                                <a
                                    class="btn btn-info mb-1 btn-xs"
                                    :href="props.rowData.link"
                                    v-if="props.rowData.link"
                                    >Link</a
                                >
                                <button
                                    class="btn btn-primary mb-1 btn-xs"
                                    @click="edit(props.rowData)"
                                >
                                    Edit
                                </button>
                            </template>
                        </vuetable>
                        <vuetable-pagination
                            ref="pagination"
                            @vuetable-pagination:change-page="onChangePage"
                        ></vuetable-pagination>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { toDateTime } from "utils/date";
import Vuetable from "vuetable-2/src/components/Vuetable.vue";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination.vue";
import Spinner from "../Spinner";
// import InputMod from "./vidgets/InputMod.vue";
export default {
    components: {
        Vuetable,
        VuetablePagination,
        Spinner,
        // InputMod,
    },
    data() {
        return {
            disabled: false,
            loaded: false,
            fileUploadError: false,
            fileUpload: false,
            errors: {},
            amount: 0,
            balance: "...",
            price: 0.0,
            form: {
                hashtags: "",
                usernames: "",
                location: "",
                count: 1000,
                full: false,
                commit: false,
                kind: "U",
                actuality: "m",
            },
            fields: [
                { name: "name", title: "Name" },
                { name: "requested_count", title: "Count" },
                { name: "worked_count", title: "Rows" },
                {
                    name: "created_at",
                    title: "Date of creation",
                    callback: toDateTime(this.$root.lang),
                },
                { name: "__slot:actions", title: "Actions" },
            ],
            tableData: {},
            suggestions: {
                hashtags: ["ntcn", "lakdflk", "dklnfdlk", "finoubihrvalkc"],
                usernames: ["ntklsd;fcn", "llns19ek", "dklnsdkvjlk"],
                locations: ["32d;fcn", "lek", "dk674745635"],
            },
        };
    },
    methods: {
        fetch(data) {
            data = data || {};
            this.$root.Dataset.get(data)
                .then((resp) => {
                    this.tableData = resp.body;
                })
                .finally(() => {
                    this.loaded = true;
                });
        },
        check({ target }) {
            this.form.commit = false;
            let form = structuredClone(this.form);
            if (target.id != "location")
                form[target.id] = this.form[target.id].split(", ");
            this.$root.Dataset.save(form).then((resp) => {
                if (resp.body.errors) {
                    this.errors = resp.body.errors;
                    return;
                }
                this.errors = {};
                this.price = resp.body.price;
                this.amount = resp.body.amount;
                this.balance = resp.body.balance;
            });
        },
        create() {
            this.disabled = true;
            this.form.commit = true;
            this.$root.Dataset.save(this.form)
                .then((resp) => {
                    this.fetch();
                })
                .finally(() => {
                    this.disabled = false;
                });
        },
        edit(data) {
            let name = prompt("Change name", data.name);
            if (name) {
                this.$root.Dataset.update({ id: data.id, name }).then(
                    (resp) => {
                        this.fetch();
                    }
                );
            }
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.fetch({ page });
        },
        chooseSingleSuggetion({ target }) {
            this.form[target.parentElement.previousElementSibling.id] =
                target.innerText;
        },
        chooseMultiSuggetion({ target }) {
            this.form[target.parentElement.previousElementSibling.id] +=
                target.innerText;
        },
        uploadFile({ target }) {
            if (!target.files[0].name.match(/\.csv$/gi)) {
                target.value = "";
                this.fileUploadError = true;
                this.fileUpload = false;
            } else {
                this.fileUploadError = false;
                this.fileUpload = true;
            }
        },
    },
    created() {
        this.fetch();
    },
};
</script>
