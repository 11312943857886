<template>
<section class="section-border border-primary">
    <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center gx-0 min-vh-100">
            <div class="col-12 order-md-1 mb-auto mb-md-0 pb-8 py-md-6" v-if="invoice.id">
                <h1 class="display-3 fw-bold text-center">{{ $root.siteName }}</h1>
                <p class="mb-5 text-center text-muted">{{ $root.siteName }} Tax Invoice and Receipt</p>
                <div class="mb-5 col-6">
                    <dl class="row">
                        <dt class="col-sm-3">DATE</dt>
                        <dd class="col-sm-9"><strong>{{ parseInt(Date.now() / 1000) | toDate }}</strong></dd>
                        <dt class="col-sm-3">REFERENCE</dt>
                        <dd class="col-sm-9">{{ invoice.id }}</dd>
                        <dt class="col-sm-3">BILLED TO</dt>
                        <dd class="col-sm-9">{{ $root.context.billed_to }}</dd>
                        <dt class="col-sm-3">ADDRESS</dt>
                        <dd class="col-sm-9">{{ $root.context.address }}</dd>
                        <dt class="col-sm-3">EMAIL</dt>
                        <dd class="col-sm-9">{{ $root.context.email }}</dd>
                    </dl>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">DESCRIPTION</th>
                            <th scope="col">TAX</th>
                            <th scope="col">AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Communication and data service<br>
                                <small class="text-muted">{{ invoice.paid_at | toDate }}</small>
                            </td>
                            <td>{{ invoice.currency__symbol }}0.00<br><small class="text-muted">{{ invoice.currency__code }}</small></td>
                            <td>{{ invoice.currency__symbol }}{{ invoice.amount }}<br><small class="text-muted">{{ invoice.currency__code }}</small></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <strong>Payment {{ invoice.is_success ? "received" : "declined" }}</strong><br>
                            </td>
                            <td>
                                {{ invoice.currency__symbol }}{{ invoice.is_success ? invoice.amount : 0 }}<br>
                                <small class="text-muted">{{ invoice.currency__code }}</small>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center py-md-6">{{ $root.siteName }}</div>
                <p class="text-center py-md-4">
                    <a :href="`mailto:${$root.siteEmail}`">{{ $root.siteEmail }}</a>
                </p>
            </div>
            <Spinner v-else />
        </div>
    </div>
</section>
</template>

<script>
import { toDate } from "../utils/date";
import Spinner from './Spinner'
export default {
    components: { Spinner },
    filters: { toDate: toDate("en") },
    data() {
        return {
            invoice: {},
        }
    },
    methods: {
        fetch() {
            this.$root.Invoice.get(this.$route.params).then(resp => {
                this.invoice = resp.body;
            })
        },
    },
    created() {
        this.fetch()
    }
}
</script>
