<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div v-if="$root.context.token">
                    To receive notifications on your telegram go to
                    <a :href="`https://t.me/${$root.context.tg_bot}?start=${$root.context.token}`">the link</a>
                </div>
                <Spinner v-else />
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Spinner from '../Spinner';

export default {
    components: { Spinner },
    data() {
        return {
        }
    },
}
</script>
