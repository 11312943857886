const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';

function toDate (locale) {
    locale = locale || getLanguage()
    return function (value) {
        if (!value)
            return ""
        let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return new Intl.DateTimeFormat(locale, options).format(new Date(parseInt(value) * 1000))
    }
}

function toDateTime (locale) {
    locale = locale || getLanguage()
    return function (value) {
        if (!value)
            return ""
        let options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
        return new Intl.DateTimeFormat(locale, options).format(new Date(parseInt(value) * 1000))
    }
}

function toHour (locale) {
    locale = locale || getLanguage()
    return function (value) {
        if (!value)
            return ""
        let options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}
        return new Intl.DateTimeFormat(locale, options).format(new Date(parseInt(value) * 1000))
    }
}

module.exports = { toDate, toDateTime, toHour };
