<template>
    <section class="py-8 py-md-11 md-n14">
        <div class="container">
            <div
                class="row"
                id="portfolio"
                data-isotope='{"layoutMode": "fitRows"}'
            >
                <div v-for="caseItem in cases" class="col-4">
                    <div class="card lift lift-lg shadow-light-lg mb-7">
                        <div class="card-body">
                            <h4 class="mb-1">{{ caseItem.title }}</h4>
                            <p>{{ caseItem.description }}</p>
                            <a
                                v-bind:href="caseItem.url"
                                class="btn btn-primary shadow me-1 d-flex align-items-center justify-content-between"
                                download
                            >
                                Download
                                <i class="fe fe-arrow-down lift"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            cases: [],
        };
    },
    mounted() {
        this.$root.Showcases.get()
            .then((res) => res.json())
            .then((res) => this.cases.push(...res));
    },
};
</script>
