<template>
<section class="section-border border-primary">
    <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center gx-0 min-vh-100">
            <div class="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">
                <img src="/assets/img/illustrations/illustration-4.png" alt="..." class="img-fluid">
            </div>
            <div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11" v-if="sent">
                <div class="alert alert-success" role="alert">
                    <h4 class="alert-heading">Excellent!</h4>
                    <p>We sent you an email, follow the link in the email and reset your password</p>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11" v-else>
                <h1 class="mb-0 fw-bold text-center">Password reset</h1>
                <p class="mb-6 text-center text-muted">Enter your Email to reset your password</p>
                <p class="mb-6 text-center text-danger" v-for="error in errors.__all__">{{ error }}</p>
                <form class="mb-6" @submit.prevent="forgot">
                    <div class="form-group">
                        <!-- <label class="form-label" for="email">Email</label> -->
                        <input type="email" class="form-control" id="email" :class="{'is-invalid': errors.email}"
                            placeholder="Enter your Email" v-model="form.email">
                        <small class="form-text text-danger" v-for="error in errors.email">{{ error }}</small>
                    </div>
                    <button class="btn w-100 btn-primary" type="submit" :disabled="disabled">
                        Reset the password
                    </button>
                </form>
                <p class="mb-0 fs-sm text-center text-muted">
                    Remember your password?
                    <router-link :to="{path: '/login'}">Login</router-link>.
                </p>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { EventBus } from "@/bus";
export default {
    data() {
        return {
            form: {},
            errors: {},
            disabled: false,
            sent: false
        }
    },
    methods: {
        forgot() {
            this.disabled = true
            this.errors = {}
            this.$root.Forgot.save(this.form).then(resp => {
                this.errors = resp.body.errors || {}
                if (resp.body.state) {
                    this.sent = true
                }
                this.disabled = false
            }).catch(resp => {
                this.errors = {'__all__': [resp.statusText]}
            })
            setTimeout(() => {
                this.disabled = false
            }, 10000)
        }
    }
}
</script>
