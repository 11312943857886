<template>
    <section class="position-relative">
        <div class="container">
            <div class="d-flex justify-content-center m-12">
                <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { NotifyMixin } from "@/mixins";
export default {
    mixins: [NotifyMixin],
    created() {
        let data = Object.assign({}, this.$route.params)
        this.$root.Plan.update(data).then(resp => {
            if (resp.body.messages) {
	        let message = resp.body.messages[0]
                if (resp.body.state) {
                    this.successNotify({ message });
                } else {
                    this.errorNotify({ message });
                }
            }
            this.$router.push("/plan")
        })
    }
}
</script>
