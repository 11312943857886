export let NotifyMixin = {
    notifications: {
        successNotify: {
            title: "",
            message: "Successful change",
            type: "success"
        },
        errorNotify: {
            title: "",
            message: "Unknown error",
            type: "error"
        }
    }
}

export let ActionMixin = {
    methods: {
        action(resource, action, id, data) {
            data = Object.assign(
                {
                    id,
                    action
                },
                data || {}
            );
            return resource.update(data).catch(err => {
                let message = err.toString();
                if (message == "[object Object]")
                    message = `${err.statusText}: ${err.bodyText.slice(
                        0,
                        100
                    )}...`;
                this.errorNotify({ message });
            });
        },
    }
}
