<template>
<div>
    <section class="pt-4 pt-md-11">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-5 col-lg-6 order-md-2">
                    <img src="/assets/img/illustrations/illustration-7.png" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" alt="..." data-aos="fade-up" data-aos-delay="100">
                </div>
                <div class="col-12 col-md-7 col-lg-6 order-md-1" data-aos="fade-up">
                    <h1 class="display-4 text-center text-md-start">
                        <span class="text-primary text-nowrap">
                            Social Networks Cache
                        </span><br>
                    </h1>
                    <p class="lead text-center text-md-start text-muted mb-2">
                        We provide fault tolerant
                        <a :href="`${$root.siteProto}://api.${$root.siteDomain}/docs`">RESTful API</a>
                        for getting a cache of data from social networks for use in market research
                    </p>
                    <p class="fs-lg text-primary-desat mb-2" data-aos="fade-right" data-aos-delay="200">
                        Anonymous getting of user, posts, stories, highlights</p>
                    <p class="fs-lg text-muted mb-2" data-aos="fade-left" data-aos-delay="200">
                        Anonymous getting an email and phone number, if the user specified them in his business profile</p>
                    <p class="fs-lg text-primary-desat mb-2" data-aos="fade-right" data-aos-delay="200">
                        Anonymous getting of post, story, album, reels data</p>
                    <p class="fs-lg text-muted mb-2" data-aos="fade-left" data-aos-delay="200">
                        Anonymous getting of hashtag and location data</p>
                    <div class="text-center text-md-start" v-show="!$root.context.id">
                        <router-link :to="{path: '/login'}" class="btn btn-primary shadow lift me-1">
                            Login <i class="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                        </router-link>
                        <router-link :to="{path: '/registration'}" class="btn btn-primary-soft lift">
                            Registration
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="pt-8 pb-4 pt-md-10 pb-md-6">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10 text-center">
                    <h2>You can choose <span class="fw-bold text-primary-desat">the plan that suits you</span></h2>
                    <p>
                        If you need up-to-date data, then we advise you to consider the Day or Week plan
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="py-1 py-md-2">
        <Plans />
    </section>
    <section class="pt-6 pb-8 pt-md-10">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-5 col-lg-6 order-md-2">
                    <img src="assets/img/illustrations/illustration-3.png" alt="..." class="img-fluid mb-6 mb-md-0">
                </div>
                <div class="col-12 col-md-7 col-lg-6 order-md-1">
                    <h2>
                        Stay focused on your business<br>
                        <span class="text-primary">Trust us to make API requests</span>
                    </h2>
                    <p class="fs-lg text-gray-700 mb-6">
                        You no longer need to handle errors, we independently make requests through our proxies, automatically solve problems and provide you with unhindered access to public data
                    </p>
                    <div class="d-flex">
                        <div class="pe-5">
                            <h3 class="mb-0">
                                <span data-countup='{"startVal": 0}' data-to="99" data-aos data-aos-id="countup:in"></span>%
                            </h3>
                            <p class="text-gray-700 mb-0">
                                Uptime
                            </p>
                        </div>
                        <div class="border-start border-gray-300"></div>
                        <div class="px-3">
                            <h3 class="mb-0">
                                <span data-countup='{"startVal": 0}' data-to="24" data-aos data-aos-id="countup:in"></span>/
                                <span data-countup='{"startVal": 0}' data-to="7" data-aos data-aos-id="countup:in"></span>
                            </h3>
                            <p class="text-gray-700 mb-0">
                                Support
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-2 py-md-6">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 text-center">
                    <h2 v-show="!$root.context.id">
                        <router-link class="fw-bold text-primary-desat" :to="{path: '/registration'}">
                            Register
                        </router-link> and start using our service!
                    </h2>
                    <p class="fs-lg text-muted mb-7 mb-md-9">
                        Top up your account in your personal dashboard, get token and use any plan
                    </p>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import Plans from './Plans'
export default {
    components: { Plans },
    data() {
        return {}
    },
    created() {
        if (this.$route.params && this.$route.params.promocode)
            localStorage.setItem("promocode", this.$route.params.promocode);
        if (location.search.indexOf("paymentId") != -1)
            this.$router.replace({path: '/billing'})
    }
}
</script>
