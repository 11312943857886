<template>
<div>
    <Spinner v-if="!loaded" />
    <div class="table-responsive" v-else>
        <vuetable
            ref="vuetable"
            :api-mode="false"
            :data="tableData"
            class="ui celled table unstackable table-scroll"
            wrapper-class="vuetable-wrapper ui basic segment"
            table-wrapper=".vuetable-wrapper"
            :fields="fields"
            @vuetable:pagination-data="onPaginationData"
            >
            <template slot="amount" slot-scope="props">
                {{ props.rowData.currency__symbol }}{{ props.rowData.amount }}
            </template>
            <template slot="state" slot-scope="props">
                <span class="me-4" v-if="props.rowData.is_success">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><circle fill="green" opacity=".3" cx="12" cy="12" r="10"></circle><path d="M16.769 7.818a1 1 0 011.462 1.364l-7 7.5a1 1 0 01-1.382.077l-3.5-3a1 1 0 011.302-1.518l2.772 2.376 6.346-6.8z" fill="white"></path></g></svg>
                </span>
                <span class="me-4" v-else>
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"></path><circle fill="red" opacity=".3" cx="12" cy="12" r="10"></circle><path d="M12.036 10.621l2.828-2.828a1 1 0 011.414 1.414l-2.828 2.829 2.828 2.828a1 1 0 01-1.414 1.414l-2.828-2.828-2.829 2.828a1 1 0 11-1.414-1.414l2.828-2.828-2.828-2.829a1 1 0 011.414-1.414l2.829 2.828z" fill="white"></path></g></svg>
                </span>
            </template>
        </vuetable>
        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
    </div>
</div>
</template>

<script>
import { toDateTime } from "utils/date";
import Vuetable from "vuetable-2/src/components/Vuetable.vue";
import VuetablePagination from "./VuetablePagination";

export default {
    components: {
        Vuetable,
        VuetablePagination,
    },
    data() {
        return {
            loaded: false,
            fields: [
                { name: "id", title: "ID" },
                { name: "created_at", title: "Created at", callback: toDateTime(this.$root.lang) },
                { name: "__slot:amount", title: "Amount" },
                { name: "status", title: "Status" },
                { name: "kind", title: "Type" },
                { name: "plan__name", title: "Plan" },
            ],
            tableData: {}
        }
    },
    methods: {
        fetch(data) {
            data = data || {}
            this.loaded = true // hack
            this.$root.Transaction.get(data).then(resp => {
                this.tableData = resp.body;
            }).finally(() => {
                this.loaded = true
            })
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.fetch({ page });
        }
    },
    created() {
        this.fetch()
    }
}
</script>
